<template>
  <div class="admin-edit-api-client">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        {{ canEditApiClient ? 'Edit API Client' : 'API Client Details' }}
        </h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Client credentials edit link -->
    <div v-if="!isLoading">
      <p>
        To manage client credentials and access controls
        <a href="#" @click.prevent="visitIdentityPortal"
          >visit the identity portal</a
        >
      </p>
    </div>
    <!-- / Client credentials edit link -->

    <!-- Basic Details -->
    <div v-if="!isLoading" class="form">
      <!-- Row 1 -->
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="Name">
            <a-input
              size="large"
              :disabled="!canEditApiClient"
              v-model="apiClientLocal.displayName"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Headline">
            <a-input size="large" 
            :disabled="!canEditApiClient"
            v-model="apiClientLocal.headline"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- / Row 1 -->

      <!-- Row 2 -->
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="User Name">
            <a-input
              size="large"
              :disabled="!canEditApiClient"
              v-model="apiClientLocal.grantPresenceName"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Event Callback URL">
            <a-input
              size="large"
              :disabled="!canEditApiClient"
              v-model="apiClientLocal.callbackUrl"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- / Row 2 -->
    </div>
    <!-- / Basic Details -->

    <!-- Icons -->
    <div v-if="!isLoading" class="form">
      <a-row :gutter="30">
        <a-col :span="12">
          <div
            :class="{
              'has-custom-avatar': !shouldRemoveExistingAvatar,
            }"
          >
            <a-form-item label="Group Icon">
              <div
                v-if="!shouldRemoveExistingAvatar && !avatarFileList.length"
                class="custom-avatar"
              >
                <div class="left">
                  <api-client-thumbnail
                    :larger-for-editing="true"
                    size="large"
                    :api-client="apiClient"
                    :tenant-id="tenantId"
                  ></api-client-thumbnail>
                </div>
                <div class="right" v-if="canEditApiClient">
                  <a-popconfirm
                    title="Are you sure?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="shouldRemoveExistingAvatar = true"
                  >
                    <a href="#">Remove Icon</a>
                  </a-popconfirm>
                </div>
              </div>

              <div class="upload-wrapper" v-if="canEditApiClient">
                <a-upload
                  :multiple="false"
                  :file-list="avatarFileList"
                  :before-upload="beforeAvatarUpload"
                  :remove="handleAvatarRemove"
                >
                  <a-button>
                    <a-icon type="upload"></a-icon>
                    Click to
                    {{
                      !shouldRemoveExistingAvatar || avatarFileList.length
                        ? "change"
                        : "upload"
                    }}
                  </a-button>
                </a-upload>
              </div>
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="12">
          <div
            :class="{
              'has-custom-avatar': !shouldRemoveExistingLogo,
            }"
          >
            <a-form-item label="Logo">
              <div
                v-if="!shouldRemoveExistingLogo && !logoFileList.length"
                class="custom-avatar"
              >
                <div class="left">
                  <api-client-logo
                  :larger-for-editing="true"
                    size="large"
                    :api-client="apiClient"
                    :tenant-id="tenantId"
                  ></api-client-logo>
                </div>
                <div class="right" v-if="canEditApiClient">
                  <a-popconfirm
                    title="Are you sure?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="shouldRemoveExistingLogo = true"
                  >
                    <a href="#">Remove Icon</a>
                  </a-popconfirm>
                </div>
              </div>

              <div class="upload-wrapper" v-if="canEditApiClient">
                <a-upload
                  :multiple="false"
                  :file-list="logoFileList"
                  :before-upload="beforeLogoUpload"
                  :remove="handleLogoRemove"
                >
                  <a-button>
                    <a-icon type="upload"></a-icon>
                    Click to
                    {{
                      !shouldRemoveExistingLogo || logoFileList.length
                        ? "change"
                        : "upload"
                    }}
                  </a-button>
                </a-upload>
              </div>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
      <p>
        Please upload a 200px x 200px image for the group icon.
        Please upload a 240px x 80px image for the logo.
      </p>
    </div>
    <!-- / Icons -->

    <!-- Permissions -->
    <div
      class="form"
      v-if="!isLoading"
      :class="{ last: !isCreateViaEmailPermissionSelected }"
    >
      <a-form-item
        style="margin-bottom: 0"
        label="Requested Permissions"
      ></a-form-item>
      <permission-selector
        :disabled="!canEditApiClient"
        :selected-permission-values="selectedPermissionValues"
        @toggle-permission="togglePermissionSelected"
      ></permission-selector>
    </div>
    <!-- / Permissions -->

    <!-- Email Parser -->
    <div class="form" v-if="!isLoading && isCreateViaEmailPermissionSelected">
      <a-form-item label="Email Parser">
        <a-input
          size="large"
          v-model="apiClientLocal.requestedParser"
        ></a-input>
      </a-form-item>
    </div>
    <!-- / Email Parser -->

    <a-button
      v-if="!isLoading && canEditApiClient"
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import ApiClientThumbnail from "../../../Incidents/Show/Tabs/Escalate/ShareWith/ApiClientCard/ApiClientThumbnail.vue";
import ApiClientLogo from "./Edit/ApiClientLogo.vue";
import apiClients from "../../.././../api/api-clients";
import PermissionSelector from "../../../../components/Admin/PermissionSelector.vue";
import apiClientHelpers from "../../../../helpers/api-clients";
import { mapGetters } from "vuex";
const _ = require("lodash");

export default {
  components: {
    LoadingScreen,
    ApiClientThumbnail,
    ApiClientLogo,
    PermissionSelector,
  },
  data() {
    return {
      apiClientLocal: {},

      avatarFileList: [],
      logoFileList: [],

      selectedPermissionValues: [],

      isSaving: false,

      shouldRemoveExistingAvatar: false,
      shouldRemoveExistingLogo: false,
    };
  },

  created() {
    if (!this.apiClient) {
      this.$message.info("API client not found");
      this.$router.push("/admin/integration/api-clients");
    } else {
      this.apiClientLocal = {
        ...this.apiClient,
      };
      _.each(this.apiGrantPermissionOptions, (permission) => {
        if (
          apiClientHelpers.hasPermission(
            this.apiClientLocal.requestedPermissions,
            permission.value
          )
        ) {
          this.selectedPermissionValues.push(permission.value);
        }
      });
    }
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminApiClients", {
      apiClients: "apiClients",
      apiGrantPermissionOptions: "apiGrantPermissionOptions"
    }),

    ...mapGetters("permissions", {
      canEditApiClient: "canEditApiClient"
    }),

    apiClientId() {
      return this.$route.params.id;
    },

    apiClient() {
      return _.find(this.apiClients, {
        id: this.apiClientId,
      });
    },

    createViaEmailPermission() {
      return { description: "Create new incidents via email", value: 8192 };
    }, 

    isCreateViaEmailPermissionSelected() {
      if (!this.createViaEmailPermission) {
        return false;
      }
      return _.includes(
        this.selectedPermissionValues,
        this.createViaEmailPermission.value
      );
    },
  },

  methods: {
    goBack() {
      this.$router.push("/admin/integration/api-clients");
    },

    handleAvatarRemove(file) {
      const index = this.avatarFileList.indexOf(file);
      const newFileList = this.avatarFileList.slice();
      newFileList.splice(index, 1);
      this.avatarFileList = newFileList;
    },

    handleLogoRemove(file) {
      const index = this.logoFileList.indexOf(file);
      const newFileList = this.logoFileList.slice();
      newFileList.splice(index, 1);
      this.logoFileList = newFileList;
    },

    beforeAvatarUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.avatarFileList = [file];
      }
      return false;
    },

    beforeLogoUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.logoFileList = [file];
      }
      return false;
    },

    togglePermissionSelected(val) {
      this.selectedPermissionValues = _.xor(this.selectedPermissionValues, [
        val,
      ]);
    },

    getValidationErrors() {
      let errors = [];
      if (this.apiClientLocal.displayName.trim().length == 0) {
        errors.push("Please provide a name");
      }
      return errors;
    },

    visitIdentityPortal() {
      window.open(
        window.authActiveProfileAuthority + "/Clients/" + this.apiClientId,
        "_blank"
      );
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.apiClientLocal,
        requestedPermissions: _.sum(this.selectedPermissionValues),
      };

      // Update API client
      await apiClients.updateApiClient(this.tenantId, params);

      // Update thumbnail if we need to
      if (this.avatarFileList.length) {
        await apiClients.addApiClientThumb(
          this.tenantId,
          this.apiClient.id,
          this.avatarFileList[0]
        );
      } else if (this.shouldRemoveExistingAvatar) {
        await apiClients.deleteApiClientThumb(this.tenantId, this.apiClient.id);
      }

      // Update logo if we need to
      if (this.logoFileList.length) {
        await apiClients.addApiClientLogo(
          this.tenantId,
          this.apiClient.id,
          this.logoFileList[0]
        );
      } else if (this.shouldRemoveExistingLogo) {
        await apiClients.deleteApiClientLogo(this.tenantId, this.apiClient.id);
      }

      this.$message.success("API client updated successfully");
      this.$router.push("/admin/integration/api-clients");
    },
  },
};
</script>

<style lang="scss">
.admin-edit-api-client {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .has-custom-avatar {
    .ant-form-item-children {
      display: flex !important;
      flex-direction: horizontal;

      .custom-avatar {
        padding-right: 15px;
      }
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;

    &.last {
      margin-bottom: 0;
    }
  }

  .custom-avatar {
    // margin-bottom: 15px;
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 30px;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }

  .upload-wrapper {
    display: flex;
    align-items: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>