<template>
  <div class="api-client-logo-inline" :class="{'larger-for-editing': largerForEditing}">
    <a-avatar
      :size="size ? size : 'default'"
      v-if="logo"
      shape="square"
      :src="logo.url"
    />
    <a-avatar :size="size ? size : 'default'" icon="loading" v-else />
  </div>
</template>

<script>
import apiClients from "../../../../../api/api-clients";
export default {
  props: ["tenantId", "apiClient", "size", "largerForEditing"],
  data() {
    return {
      isLoadingLogo: false,
      logo: null,
    };
  },
  created() {
    this.getLogoUrl();
  },
  methods: {
    getLogoUrl() {
      let vm = this;
      vm.isLoadingLogo = true;
      apiClients
        .getApiClientLogoProtectedUrl(this.tenantId, this.apiClient.id)
        .then((r) => {
          vm.isLoadingLogo = false;
          vm.logo = r.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
.api-client-logo-inline {
  &.larger-for-editing {
    padding-top: 10px;
    .ant-avatar { 
      width: 240px !important;
      height: 80px !important;
    }
  }
}
</style>